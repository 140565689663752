const gridBreakpointXS = 0
const gridBreakpointSM = 576
const gridBreakpointMD = 768
const gridBreakpointLG = 992
const gridBreakpointXL = 1200

const getMaxDeviceWithFromNextGridBreakpoint = (nextGridBreakPoint) =>
  nextGridBreakPoint - 1;

export default {
  // gridBreakpoints
  gridBreakpointXS,
  gridBreakpointSM,
  gridBreakpointMD,
  gridBreakpointLG,
  gridBreakpointXL,
  // minDeviceWidth
  minDeviceWidthXS: gridBreakpointXS,
  minDeviceWidthSM: gridBreakpointSM,
  minDeviceWidthMD: gridBreakpointMD,
  minDeviceWidthLG: gridBreakpointLG,
  minDeviceWidthXLG: gridBreakpointXL,
  // maxDeviceWidth
  maxDeviceWidthXS: getMaxDeviceWithFromNextGridBreakpoint(gridBreakpointSM),
  maxDeviceWidthSM: getMaxDeviceWithFromNextGridBreakpoint(gridBreakpointMD),
  maxDeviceWidthMD: getMaxDeviceWithFromNextGridBreakpoint(gridBreakpointLG),
  maxDeviceWidthLG: getMaxDeviceWithFromNextGridBreakpoint(gridBreakpointXL),
}