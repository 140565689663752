import { Footer, Header } from 'components'
import React, { useEffect } from 'react'

import { CookiePolicy as CookiePolicyContainer } from 'containers'
import { helmet } from 'utils';
import styles from './CookiePolicy.module.scss'

const SEO_Data = helmet({
  title: 'Cookie Richtlinie',
  description: 'Erfahren Sie mehr über Cookies, unseren Einsatz derselbigen und noch ein paar Dinge mehr.'
})

const CookiePolicy = () => {
  
  useEffect(() => {
    window.scroll(0,0)
  });

  return (
    <div>
      {/* Insert Helmet Data */}
      {SEO_Data}

      {/* Render Screen */}
      <Header />
      <article className={styles.content}>
        <CookiePolicyContainer />
      </article>
      <Footer />
    </div>
  )
}

export { CookiePolicy as default }