import { Icon, Logo } from 'components'

import { Link } from 'react-router-dom'
import React from 'react'
import styles from './Footer_Regular.module.scss'

const FooterRegular = ({
  copyright,
  description,
  sectionOne,
  sectionTwo,
  legal,
  socialMedia
}) => {

  const _renderSection = (section) => (
    <ul className={styles.section}>
      { section.map(x => (
          <li className={styles.section_item}>
            { x.to ? 
              <Link to={x.to} className={styles.section_link}> 
                {x.name} 
              </Link>
              : <span className={styles.section_text}> {x.name} </span>
            }
          </li>
        ))}
    </ul>
  )

  const _renderCreatorInfo = () => (
    <div className={styles.creatorInfo}>
      <div>
        Made with <Icon icon={['fas', 'heart']} className={styles.creatorInfo_icon} /> by 
        <a href={'https://www.weborilla.de'} target='_blank' rel="noopener noreferrer" className={styles.creatorInfo_logo}> Weborilla </a>
      </div>
      <div className={styles.copyright}>
        <Icon type={['far', 'copyright']} className={styles.copyright_icon}/> {copyright} - {new Date().getFullYear()}
      </div>
    </div>
  )

  return (
    <footer className={styles.background}>
      <div className={styles.wrapper}>
        <div className={styles.company}>
          <Logo negative />
          <p className={styles.company_description}>
            {description}
          </p>
        </div>
        { sectionOne ? _renderSection(sectionOne) : null }
        { sectionTwo ? _renderSection(sectionTwo) : null }
        { _renderSection(legal)}
        { socialMedia ? _renderSection(socialMedia) : null }
      </div>
      <hr className={styles.divider} />
      {_renderCreatorInfo()}
    </footer>
  )

}

export { FooterRegular as default }