import React from 'react'
import store from 'stores/store'
import styles from './CookiePolicy.module.scss'

const CookiePolicy = () => {
  return (
    <div className={styles.wrapper}>
      <section className={styles.row}>
        <div>
          <h1 className={styles.header}>
            Cookie Richtlinie
          </h1>
          <p className={styles.update}>
            Letztes Update: {store.cookies.last_update}
          </p>
        </div>
        <div className={styles.image_container}>
        </div>
      </section>

      {/* Cookies Text */}
      <div className={styles.content}>
        {store.cookies.content}
      </div>

    </div>
  )
}

export { CookiePolicy as default }