import React from 'react'
import eRecht24_Disclaimer from 'assets/images/erecht24-disclaimer.png';
import eRecht24_Impressum from 'assets/images/erecht24-impressum.png';
import store from 'stores/store'
import styles from './Imprint.module.scss'

const Imprint = () => {
  return (
    <div className={styles.wrapper}>
      <section className={styles.row}>
        <div>
          <h1 className={styles.header}>
            Impressum
          </h1>
          <p className={styles.update}>
            Letztes Update: {store.imprint.last_update}
          </p>
        </div>
        <div className={styles.image_container}>
          <img 
            src={eRecht24_Impressum} 
            className={styles.image_impressum}
            alt='eRecht24 - Rechtssicheres Impressum' 
          />
          <img 
            src={eRecht24_Disclaimer} 
            className={styles.image_disclaimer}
            alt='eRecht24 - Rechtssicherer Disclaimer' 
          />
        </div>
      </section>

      {/* Imprint Text */}
      <section className={styles.content}>
        {store.imprint.content}
      </section>

    </div>
  )
}

export { Imprint as default }