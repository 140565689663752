import { Footer, Header } from 'components'
import React, { useEffect } from 'react'

import { Home as HomeContainer } from 'containers'
import { helmet } from 'utils';
import styles from './Home.module.scss'

const SEO_Data = helmet({
  title: 'Zierof Prüftechnik GmbH',
  description: 'Ihr Professioneller Service Partner bei Prüfung, Reparatur und Verkauf von Neu-, Gebraucht- und Ersatzteilen für Krane.'
})

const Home = () => {

  useEffect(() => {
    window.scroll(0,0)
  });

  return (
    <div>
      {/* Insert Helmet Data */}
      {SEO_Data}

      {/* Render Screen */}
      <Header />
      <article className={styles.content}>
        <HomeContainer />
      </article>
      <Footer />
    </div>
  )
}

export { Home as default }