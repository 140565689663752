// Basic Header Data 
//(Don't forget to adapt padding-top css variable)

const type = 'fixed'

// Navigation Links
const navLinks = [
  {
    name: 'Leistungen',
    to: '/leistungen'
  }, {
    name: 'Prüfvorrichtung',
    to: '/pruefvorrichtung'
  }, {
    name: 'Karriere',
    to: '/karriere'
  }
]

// Action Buttons
const buttons = [
  {
  name: 'Kontakt',
  to: '/kontakt'
  }
]

export {
  type,
  navLinks,
  buttons
}