import CookieStore from './CookieStore'
import ImprintStore from './ImprintStore'
import PrivacyStore from './PrivacyStore'

const store = {
  imprint: new ImprintStore(),
  privacy: new PrivacyStore(),
  cookies: new CookieStore(),
};

export default store;