import {decorate, observable} from 'mobx';

import React from 'react'

class CookieStore {
  last_update = '16. Juni 2019'
  content = (
    <div>

      <h2>Über diese Richtlinie</h2> 
      <p>Zusätzlich zu unserer Datenschutzerklärung, enthält diese Cookie Richtlinie weitere Informationen über die unterschiedlichen Arten von Cookies, die auf unserer Website verwendet werden. 
        Diese Richtlinie wird regelmäßig auf Ihre Aktualität überprüft. 
        Sollten Änderungen vorgenommen werden, so wird die aktuellste Version hier veröffentlicht. 
        Im Falle bedeutender Änderungen informieren wir Sie bei der nächsten Nutzung unserer Website oder über alternative Kommunikationswege.
      </p>  

      <h2>Was sind Cookies?</h2> 
      <p> 
        Cookies sind kleine Textdateien (bis zu 4 KB), die von einer Website erstellt und auf dem Computer des Benutzers entweder temporär für eine einzelne Sitzung oder dauerhaft gespeichert werden. 
        Cookies ermöglichen es einer Website, Sie zu erkennen und Ihre Präferenzen zu verfolgen.
      </p>

      <h2>Wie verwenden wir Cookies?</h2> 
      <p> 
        Wir selbst verwenden auf unserer Website keine Cookies, sondern setzen voll und ganz auf moderne "Cookieless technology". 
        Diese ermöglicht, ohne jeglichen Einsatz von Cookies, neben der optimierten Bereitstellung der Website, ebenso eine vollkommen anonymisierte Analyse Ihrer Nutzung derselbigen.
        Wir verzichten somit auf jegliche Datenspeicherung auf dem Computer des Benutzers und garantieren damit den Schutz der Privatsphäre.      
      </p>

      <h2>Weitere Informationen</h2> 
      <p> 
        Sollten Sie Fragen bezüglich dieser Cookie Richtlinie haben oder möchten weitere Informationen hierzu erhalten, 
        so können Sie uns gerne jederzeit kontaktieren.
      </p>

    </div>
  )
}

decorate(CookieStore, {
	content: observable,
});
export default CookieStore