import { Button, Icon } from 'components'

import React from 'react'
import family from 'assets/images/home-family.jpg'
import gewicht from 'assets/images/home-gewicht.jpg'
import hebebuehne from 'assets/images/home-hebebuehne.jpeg'
import pruefV from 'assets/images/home-pruefv.jpeg'
import styles from './Custom_01.module.scss'
import zubehoer from 'assets/images/home-zubehoer.jpg'

const Custom01 = () => {
  return (
    <div>

      <section className={styles.hero}>
        <div className={styles.wrapper}>
          <div className={styles.box}>
            <h1 className={styles.headline}>
              Unsere Leistungen
            </h1>
            <p className={styles.text}>
              Wir beraten und liefern unabhängig die bestmögliche und wirtschaftlichste Lösung zum Heben Ihrer Lasten.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.family}>
        <div className={styles.family_column}>
          <h2 className={styles.headline}>
            Prüfung & Reparatur
          </h2>
          <p>Als zertifizierter Servicepartner der Abus Kransysteme GmbH erbringen wir folgende Leistungen an Krananlagen:</p>
          <ul>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Neumontagen</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Umbauten</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Reparaturen</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Wiederkehrende Prüfungen</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Serviceleistungen</li>
          </ul>
        </div>
        <div className={styles.family_column}>
          <img className={styles.family_image} src={family} alt='Zierof Prueftechnik - Ein Familienunternehmen' />
        </div>
      </section>

      <section className={styles.family_reverse}>
        <div className={styles.family_column}>
          <img className={styles.family_image} src={zubehoer} alt='Zierof Prueftechnik - Ein Familienunternehmen' />
        </div>
        <div className={styles.family_column}>
          <h2 className={styles.headline}>
            Zubehör
          </h2>
          <p>Bei uns erhalten Sie nicht nur kompetenten Service für Ihre Krananlagen, sondern auch Zubehör aller Art zu fairen Preisen. </p>      
          <ul>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Lastaufnahmemittel</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Anschlagmittel</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Winden, Hub- und Zuggeräte</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Persönliche Schutzausrüstung</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Leitern und Tritte</li>
          </ul>
        </div>
      </section>

      <section className={styles.family}>
        <div className={styles.family_column}>
          <h2 className={styles.headline}>
            Vermietung Hebebühnen
          </h2>
          <p>Um Ihnen Zeit und Kosten zu sparen, können wir Ihnen bis zu einer Kranhöhe von 10m zum Prüftermin eine firmeneigene Hubarbeitsbühne anbieten.</p>
          <ul>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Tagespauschale: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 176,00 €</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Anfahrt bis 80km: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 0,- €</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Anfahrt bis 115km: &nbsp;&nbsp;&nbsp;&nbsp; 31,00 €</li>
            <li><Icon className={styles.icon} type={['fas', 'chevron-right']} />Anfahrt bis 150km: &nbsp;&nbsp;&nbsp;&nbsp; 62,00 €</li>
          </ul>
          <p className={styles.small}>(Auf Anfrage sind auch Hubarbeitsbühnen für andere Krane verfügbar.)</p>
        </div>
        <div className={styles.family_column}>
          <img className={styles.family_image} src={hebebuehne} alt='Zierof Prueftechnik - Ein Familienunternehmen' />
        </div>
      </section>

      <section className={styles.family_reverse}>
        <div className={styles.family_column}>
          <img className={styles.family_image} src={gewicht} alt='Zierof Prueftechnik - Ein Familienunternehmen' />
        </div>
        <div className={styles.family_column}>
          <h2 className={styles.headline}>
            Vermietung Prüfgewichte
          </h2>
          <p>
            Um den Komplettservice an Ihrem Kran zu vollenden, liefern wir auf Anfrage auch gern die passenden Prüfgewichte. 
            <br/><br/>
            Eine Anmietung von Prüfgewichten ist hierbei bis zu einem Gesamtgewicht von 200 Tonnen möglich.
          </p>      
        </div>
      </section>

      <section className={styles.pruefV}>
        <div className={styles.pruefV_column}>
          <h2 className={styles.headline}>
            Prüfvorrichtung
          </h2>
          <p>
            Dieser Prüfstand dient zur Prüfung von Hebe-, Zug- und Spanngeräten nach durchgeführten Instandsetzungen oder zur jährlichen, von der BetrSichV geforderten, Überprüfung.
            <br/><br/>
            Der Prüfstand ist vorrangig für den mobilen Zweck entwickelt und deshalb demontierbar.
          </p>      
          <Button size='large' to='/pruefvorrichtung'>Mehr erfahren</Button>
        </div>
        <div className={styles.pruefV_column}>
          <img className={styles.pruefV_image} src={pruefV} alt='Zierof Prueftechnik - Ein Familienunternehmen' />
        </div>
      </section>

    </div>
  )
}

export { Custom01 as default }
