import { Icon } from 'components'
import React from 'react'

// Basic Footer Data

const type = 'regular'
const description = 'Ihr Professioneller Service Partner bei Prüfung, Reparatur und Verkauf von Neu-, Gebraucht- und Ersatzteilen für Krane.'
const copyright = 'Zierof Prüftechnik GmbH'

// Section One - Custom Values
const sectionOne = [
  {
    name: 'Wir stellen ein:'
  }, {
    name: 'Servicetechniker (m/w/d)',
    to: '/Karriere'
  }
]

// Section Two - Custom Values
const sectionTwo = [
  {
    name: 'Kontakt'
  }, {
    name: 'Werktags von 8 - 18 Uhr',
  }, {
    name: '+49 (0)8374 / 2415468',
  }, {
    name: 'tobias@zierof-prueftechnik.de',
  }
]

// Social Media - Custom Values
const socialMedia = null
// [
//   {
//     name: 'Social Media'
//   }, {
//     name: 'Facebook',
//     to: '/'
//   }, {
//     name: 'Linkedin',
//     to: '/impressum'
//   }
// ]

// Legal - Fixed Values
const legal = [
  {
    name: 'Rechtliches'
  }, {
    name: 'Datenschutz',
    to: '/datenschutz'
  }, {
    name: 'Cookies',
    to: '/cookies'
  }, {
    name: 'Impressum',
    to: '/impressum'
  }
]

export {
  type,
  description,
  copyright,
  sectionOne,
  sectionTwo,
  legal,
  socialMedia
}