// Import Solid Icons

import {
  faBars,
  faChevronRight,
  faEnvelope,
  faHeart,
  faPhone,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import {
  faCopyright
} from '@fortawesome/free-regular-svg-icons'

// Import Regular Icons


export default [
  // solid
  faHeart,
  faBars,
  faTimes,
  faPhone,
  faEnvelope,
  faChevronRight,
  // regular
  faCopyright
]