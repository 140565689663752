import { Button } from 'components'
import React from 'react'
import pruef01 from 'assets/images/pruef01.jpeg'
import pruef02 from 'assets/images/pruef02.jpeg'
import pruef03 from 'assets/images/pruef03.jpeg'
import styles from './Custom_02.module.scss'

const Custom02 = () => {
  return (
    <div>

      <section className={styles.hero}>
        <div className={styles.wrapper}>
          <div className={styles.box}>
            <h1 className={styles.headline}>
              Unsere Mobile Prüfvorrichtung
            </h1>
            <p className={styles.text}>
              Klein, Leicht und Leistungsstark, unser eigens entwickelter Spezialprüfstand für Hebe-, Zug- und Spanngeräte, Magnete u.v.m.
            </p>
            <p className={styles.details}>
              Der Prüfstand ist vorrangig für den mobilen Zweck entwickelt und deshalb demontierbar.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.description}>
        <div className={styles.description_inner}>
          <h2>Klein, aber oho!</h2>
          <p>
            Dieser Prüfstand dient zur Prüfung von Hebe-, Zug- und Spanngeräten, nach durchgeführten
            Instandsetzungen oder zur jährlichen, von der BetrSichV und Berufsgenossenschaft
            geforderten Überprüfung.
          </p>
          <p>
            Der Prüfstand ist vorrangig für den mobilen Zweck entwickelt, um im nicht demontierten
            Zustand, in einem VW T6 stehend genutzt und transportiert werden zu können.
            Des Weiteren ist er sehr leicht demontierbar, um auch in allen anderen Fahrzeugen
            transportiert zu werden.
          </p>
          <p>
            Durch die Verwendung von Hochfestem Stahl und einem Innovativen Kraftverlauf, ist er ein
            Leichtgewicht in seiner Klasse.
          </p>
          <p>
            Er besteht im Wesentlichen aus Grundgestell, Quertraverse, dem Hydraulikzylinder mit
            Gewindestange und Ringmutter, sowie einer Hydraulikpumpe mit Manometeranzeige.
          </p>
          <p>
            Die Prüfwerte werden anhand einer hydraulischen Messvorrichtung ermittelt. Die Anzeige
            erfolgt über ein Manometer. Kernstück des Prüfstandes ist ein Hohlkolbenzylinder mit einer
            Belastbarkeit von 10t.
          </p>
        </div>
      </section>

      <section className={styles.pictures}>
        <div className={styles.pictures_inner}>
          <img className={styles.pictures_img} src={pruef01} alt='Zierof Prueftechnik - Die Mobile Pruefvorrichtung'/>
          <img className={styles.pictures_img2} src={pruef02} alt='Zierof Prueftechnik - Die Mobile Pruefvorrichtung'/>
          <img className={styles.pictures_img3} src={pruef03} alt='Zierof Prueftechnik - Die Mobile Pruefvorrichtung'/>
        </div>
      </section>

      <section className={styles.contact}>
        <h2>Haben wir Ihr Interesse geweckt?</h2>
        <p>Gerne erstellen wir Ihnen ein individuelles Angebot. <br/> Kontaktieren Sie uns noch heute!</p>
        <Button to='/kontakt' size='large'>Jetzt Kontaktieren</Button>
      </section>

    </div>
  )
}

export { Custom02 as default }