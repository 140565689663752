import { Footer, Header } from 'components'
import React, { useEffect } from 'react'

import { Custom01 as Custom01Container } from 'containers'
import { helmet } from 'utils'
import styles from './Custom_01.module.scss'

const SEO_Data = helmet({
  title: 'Unsere Leistungen',
  description: 'Wir bei Zierof Prüftechnik beraten und liefern unabhängig die bestmögliche und wirtschaftlichste Lösung zum Heben Ihrer Lasten.'
})

const Custom01 = () => {

  useEffect(() => {
    window.scroll(0,0)
  });

  return (
    <div>
      {/* Insert Helmet Data */}
      {SEO_Data}

      {/* Render Screen */}
      <Header />
      <article className={styles.content}>
        <Custom01Container />
      </article>
      <Footer />
    </div>
  )
}

export { Custom01 as default }