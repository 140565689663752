import React from 'react'
import eRecht24_Privacy from 'assets/images/erecht24-privacy.png';
import store from 'stores/store'
import styles from './PrivacyPolicy.module.scss'

const PrivacyPolicy = () => {
  return (
    <div className={styles.wrapper}>

      <section className={styles.row}>
        <div>
          <h1 className={styles.header}>
            Datenschutzerklärung
          </h1>
          <p className={styles.update}>
            Letztes Update: {store.privacy.last_update}
          </p>
        </div>
        <div className={styles.image_container}>
          <img 
            src={eRecht24_Privacy} 
            className={styles.image}
            alt='eRecht24 - Rechtssichere Datenschutzerklärung' 
          />
        </div>
      </section>

      {/* Privacy Policy Text */}
      <section className={styles.content}>
        {store.privacy.content}
      </section>

    </div>
  )
}

export { PrivacyPolicy as default }