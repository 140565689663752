import gql from "graphql-tag"

const sendContactFormMails = gql`
  mutation sendContactFormMails(
    $name: String!
    $email: String!
  ) {
    sendContactFormMails(
      data: {
        name: $name,
        email: $email,
        sender_email: $email,
      }
    ) {
      success
    }
  }
`

export {
  sendContactFormMails
}