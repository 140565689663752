import { Icon } from 'components'
import React from 'react'
import styles from './Careers.module.scss'

const Careers = () => {
  return (
    <div className={styles.background}>

      <section className={styles.hero}>
        <div className={styles.wrapper}>
          <div className={styles.box}>
            <h1 className={styles.headline}>
              Wir freuen uns auf Dich!
            </h1>
            <p className={styles.text}>
              Du bist auf der Suche nach einer neuen Herausforderung? <br/>
              Dann bist Du bei uns genau richtig!
            </p>
            <p className={styles.details}>
              <span>Wir suchen: </span>
              <div className={styles.padding} />
              <span> - Servicetechniker im Außendienst (m/w/d)</span>
            </p>
          </div>
        </div>
      </section>

      <section className={styles.job}>
        <div>
          <h2>Servicetechniker im Außendienst (m/w/d)</h2>
          <br/>

          <h3>Ihre Aufgaben:</h3>
          <ul>
            <li>
              Durchführen von Wartungs-, Reparatur- und Umrüstarbeiten an Kranen und Hebezeugen
              bei unseren Kunden vor Ort
            </li>
            <li>Selbstständiges Diagnostizieren und Beheben von Fehlern elektrischer bzw. mechanischer Art</li>
            <li>Dokumentieren der durchgeführten Arbeiten</li>
            <li>Termin- und qualitätsgerechte Bearbeitung von Aufträgen unter Einhaltung der Sicherheitsvorschriften</li>
            <li>
              In enger Zusammenarbeit mit dem Service-Innendienst stehen Sie unseren Kunden
              als kompetenter Ansprechpartner für technische Fragestellungen zur Verfügung
            </li>
          </ul>
          
          <br/>

          <h3>Ihre Qualitäten:</h3>
          <ul>
            <li>Abgeschlossene elektrotechnische oder mechanische Ausbildung</li>
            <li>Mehrjährige Berufserfahrung, idealerweise im Bereichen Elektrik und Steuerungstechnik</li>
            <li>Flexibilität, selbständige und serviceorientierte Arbeitsweise sowie Schwindelfreiheit</li>
            <li>Ausgeprägtes Bewusstsein für Qualität und Arbeitssicherheit</li>
            <li>Sicheres Auftreten und hohe Kommunikationsfähigkeit im Kundenkontakt</li>
            <li>Führerscheinklasse B</li>
          </ul>

          <br/>

          <h3>Unsere Leistungen:</h3>
          <ul>
            <li>Abwechslungsreiches und selbstbestimmtes Aufgabengebiet</li>
            <li>Flexible Arbeitszeiten</li>
            <li>Gehalt auf Industrieniveau</li>
            <li>Unterstützung bei Fort- und Weiterbildungen</li>
          </ul>
          
          <br/><br/>

          <h3 className={styles.interested}>Du bist interessiert?</h3>
          Dann freuen wir uns auf eine aussagekräftige Bewerbung mit Angaben zu Ihren Einkommenserwartungen und möglichem Eintrittsdatum.
          Bitte per Post oder Email an:<br/><br/>	
          
          Zierof Prüftechnik GmbH<br/>
          Ritterstr. 9			<br/>
          87463 Dietmannsried	<br/><br/>	
          <Icon type={['fas', 'phone']} />&nbsp;&nbsp;+49 (0)8374 / 2415468<br/>
          <Icon type={['fas', 'envelope']} />&nbsp;&nbsp;<a href="mailto:info@zierof-prueftechnik.de">tobias@zierof-prueftechnik.de</a>
          <br/><br/>
          
        </div>
      </section>
    </div>
  )
}

export { Careers as default }