import React from 'react'
import styles from './Loading.module.scss'

const Loading = () => {
  return (
    <div>
      Loading
    </div>
  )
}

export { Loading as default }