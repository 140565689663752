import {
  copyright,
  description,
  legal,
  sectionOne,
  sectionTwo,
  socialMedia,
  type
} from 'assets/config/footer'

import FooterCentered from './Footer_Centered/Footer_Centered'
import FooterRegular from './Footer_Regular/Footer_Regular'

const Footer = () => {

  if(type === 'center') {
    return FooterCentered({
      copyright,
      description,
      legal,
      socialMedia
    })
  }

  return FooterRegular({
    copyright,
    description,
    sectionOne,
    sectionTwo,
    legal,
    socialMedia
  })

}

export { Footer as default }