import { Footer, Header } from 'components'
import React, { useEffect } from 'react'

import { Contact as ContactContainer } from 'containers'
import { helmet } from 'utils';
import styles from './Contact.module.scss'

const SEO_Data = helmet({
  title: 'Kontakt',
  description: 'Sie haben Fragen? Kontaktieren Sie uns! Wir freuen uns jederzeit von Ihnen zu hören.'
})

const Contact = () => {

  useEffect(() => {
    window.scroll(0,0)
  });

  return (
    <div>
      {/* Insert Helmet Data */}
      {SEO_Data}

      {/* Render Screen */}
      <Header />
      <article className={styles.content}>
        <ContactContainer />
      </article>
      <Footer />
    </div>
  )
}

export { Contact as default }