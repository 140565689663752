import { ContactForm } from 'components'
import React from 'react'
import styles from './Contact.module.scss'

const Contact = () => {
  return (
    <div className={styles.background}>
      {/* Custom Header */}
      <div className={styles.header}>
        <section className={styles.header_wrapper}>
          <h1 className={styles.header_h1}>
            Wir freuen uns, von Ihnen zu hören!
          </h1>
          <p className={styles.header_text}>
            Kontaktieren Sie uns hier & wir melden uns schnellstmöglich bei Ihnen.
          </p>
        </section>
      </div>

      {/* Fixed Contact Section */}
      <section className={styles.wrapper}>
        <div className={styles.flex}>
          <h2>Wir sind für Sie da!</h2>
          <p>        
            Sehr gerne beantworten wir Ihnen Ihre Fragen.
            <br/>
            Wir sind jederzeit für Sie erreichbar. 
          </p>
          <p>
            Sie können uns entweder telefonisch, per e-mail oder über unser Kontaktformular kontaktieren.
          </p>
          <p>        
            <strong className={styles.prefix}>Telefon:</strong> +49 (0)8374 / 2415468
            <br/>
            <strong className={styles.prefix}>E-Mail:</strong> <a href='mailto:tobias@zierof-prueftechnik.de'>tobias@zierof-prueftechnik.de</a>
          </p>
        </div>
      </section>
      
    </div>
  )
}

export { Contact as default }