import { Footer, Header } from 'components'
import React, { useEffect } from 'react'

import { Custom01 as Custom04Container } from 'containers'
import { helmet } from 'utils'
import styles from './Custom_04.module.scss'

const SEO_Data = helmet({
  title: 'Karriere',
  description: 'Ihre Karriere bei uns'
})

const Custom04 = () => {

  useEffect(() => {
    window.scroll(0,0)
  });

  return (
    <div>
      {/* Insert Helmet Data */}
      {SEO_Data}

      {/* Render Screen */}
      <Header />
      <article className={styles.content}>
        <Custom04Container />
      </article>
      <Footer />
    </div>
  )
}

export { Custom04 as default }