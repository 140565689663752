import { Footer, Header } from 'components'
import React, { useEffect } from 'react'

import { Careers as CareersContainer } from 'containers'
import { helmet } from 'utils'
import styles from './Careers.module.scss'

const SEO_Data = helmet({
  title: 'Karriere',
  description: 'Starten Sie durch mit einer Karriere bei der Zierof Prüftechnik GmbH. Schreiben Sie mit uns eine Erfolgsgeschichte!'
})

const Careers = () => {

  useEffect(() => {
    window.scroll(0,0)
  });

  return (
    <div>
      {/* Insert Helmet Data */}
      {SEO_Data}

      {/* Render Screen */}
      <Header />
      <article className={styles.content}>
        <CareersContainer />
      </article>
      <Footer />
    </div>
  )
}

export { Careers as default }