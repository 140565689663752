import './App.scss'

import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import { ApolloProvider } from "react-apollo"
import { Home } from 'screens'
import { Loading } from 'components'
import client from 'graphql/client.js'
import { history } from 'utils'

// Custom Routes
const Custom01 = lazy(() => import('screens/Custom_01/Custom_01'))
const Custom02 = lazy(() => import('screens/Custom_02/Custom_02'))

// Fixed Routes
const Contact = lazy(() => import('screens/Contact/Contact'))
const Careers = lazy(() => import('screens/Careers/Careers'))
const Imprint = lazy(() => import('screens/Imprint/Imprint'))
const CookiePolicy = lazy(() => import('screens/CookiePolicy/CookiePolicy'))
const PrivacyPolicy = lazy(() => import('screens/PrivacyPolicy/PrivacyPolicy'))

const _lazyload = (Component) => {
  return props => (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
}

const App = () => (
  <ApolloProvider client={client}>
    <Router history={history}>
      <Switch>
        
        {/* Custom Routes */}
        <Route exact path='/' component={Home} />
        <Route path='/leistungen' component={_lazyload(Custom01)}/>
        <Route path='/pruefvorrichtung' component={_lazyload(Custom02)}/>

        {/* General Routes */}
        <Route path='/kontakt' component={_lazyload(Contact)}/>
        <Route path='/karriere' component={_lazyload(Careers)}/>

        {/* Legal Routes */}
        <Route path='/impressum' component={_lazyload(Imprint)}/>
        <Route path='/datenschutz' component={_lazyload(PrivacyPolicy)}/>
        <Route path='/cookies' component={_lazyload(CookiePolicy)}/>

        {/* Redirect */}
        <Route path='*' render={() => (<Redirect to="/" />)} />

      </Switch>
    </Router>
  </ApolloProvider>
)

export default inject('store')(observer(App))
