import React, { Fragment } from 'react'

import { Button } from 'components'
import { Link } from 'react-router-dom'
import family from 'assets/images/home-family.jpg'
import gewichte from 'assets/images/home-gewicht.jpg'
import hebebuehne from 'assets/images/home-hebebuehne.jpeg'
import krane from 'assets/images/home-krane.jpeg'
import pruefV from 'assets/images/home-pruefv.jpeg'
import question from 'assets/images/home-question.jpg'
import styles from './Home.module.scss'
import zubehoer from 'assets/images/home-zubehoer.jpg'

const leistungen = [{
  name: 'Prüfung & Reparatur',
  picture: `${krane}`,
  to: '/leistungen'
  }, { 
  name: 'Zubehör',
  picture: `${zubehoer}`,
  to: '/leistungen'
  } , {
  name: 'Prüfvorrichtung',
  picture: `${pruefV}`,
  to: '/leistungen'
  }, { 
  name: 'Vermietung Hebebühnen',
  picture: `${hebebuehne}`,
  to: '/leistungen'
  }, {
  name: 'Vermietung Prüfgewichte',
  picture: `${gewichte}`,
  to: '/leistungen'
  }, { 
  name: 'Nicht gefunden, was Sie suchen?',
  picture: `${question}`,
  to: '/kontakt'
  }
]


const Home = () => (
  <Fragment>

    <section className={styles.hero}>
      <div className={styles.hero_wrapper}>
        <div className={styles.hero_box}>
          <h1>Prüftechnik Zierof</h1>
          <hr className={styles.hero_line} />
          <p>Ihr Professioneller Service Partner bei Prüfung, Reparatur und Verkauf von Neu-, Gebraucht- und Ersatzteilen für Krane.</p>
          <Button to='/leistungen' className={styles.hero_button} size='large' >Unsere Leistungen</Button>
        </div>
      </div>
    </section>

    <section className={styles.family}>
      <div className={styles.family_column}>
        <h2 className={styles.headline}>
          Wir sind ...
        </h2>
        <p className={styles.text}>
        ... ein Allgäuer Familienunternehmen, das sich als Komplettanbieter für Dienstleistungen rund um Industriekrane und Zubehör spezialisiert hat. <br/><br/>
        Als<span className={styles.family_focus}> Vertragspartner der Abus Kransysteme GmbH</span> erledigen wir alle Arbeiten an Ihrem Abus Kran.<br/><br/>
        Wir beraten Sie unabhängig über die bestmöglichen und wirtschaftlichsten Lösungen zum Heben Ihrer Lasten.
        </p>
      </div>
      <div className={styles.family_column}>
        <img className={styles.family_image} src={family} alt='Zierof Prueftechnik - Ein Familienunternehmen' />
      </div>
    </section>

    <section className={styles.leistungen_bg}>
      <h2>Unsere Leistungen</h2>
      <p> 
        Spitzenqualität und ein top Preis-Leistungs-Verhältnis. 
        Wir beraten und liefern unabhängig die bestmögliche und wirtschaftlichste Lösung zum Heben Ihrer Lasten.
      </p>
      <div className={styles.leistungen}>
        {
          leistungen.map(x => (
            <Link to={x.to} className={styles.leistungen_link}>
              <div className={styles.leistungen_box}>
                <img src={x.picture} alt={x.name} />
                <p>{x.name}</p>
              </div>
            </Link>
          ))
        }
      </div>
    </section>

    <section className={styles.karriere}>
      <div className={styles.karriere_column}>
        <h2 className={styles.headline}>
          Wir suchen Verstärkung!
        </h2>
        <p className={styles.karriere_text}>
          Servicetechniker im Außendienst (m/w/d)
        </p>
      </div>
      <div className={styles.karriere_column}>
        <h3>Sie sind auf der Suche nach etwas Neuem?</h3>
        <p>
          Dann sind Sie bei uns genau richtig, denn wir suchen 
          ab sofort Verstärkung für unser Team. 
        </p>
        <p>
          Falls wir Ihr Interesse geweckt haben, würden wir uns freuen von Ihnen zu hören.
        </p>
        <Button
          size='large'
          to='/karriere'
          className={styles.karriere_button}
        >
          Mehr erfahren
        </Button>
      </div>
    </section>

  </Fragment>

)

export { Home as default }