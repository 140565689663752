import React from 'react'
import styles from './Custom_03.module.scss'

const Custom03 = () => {
  return (
    <div>
    Custom03
    </div>
  )
}

export { Custom03 as default }