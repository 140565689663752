import React from 'react'
import logo from 'assets/images/logo.png'
import logo_negative from 'assets/images/logo-negative.png'
import styles from './Logo.module.scss'

const Logo = ({ mobile, size, negative }) => {

  if (mobile) {
    return (
      <span className={`
        ${size ? styles[`${size}`] : styles.medium}
      `}>
        W
      </span>
    )
  }

  return ( 
    <img 
      src={ negative ? logo_negative : logo } 
      alt='Logo' 
      className={`
        ${size ? styles[`${size}`] : styles.medium}
      `}
    />
  )
}

export { Logo as default }
