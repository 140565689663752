import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconLibrary from './IconLibrary';
import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(...IconLibrary);

const Icon = ({
  icon,
  type,
  className,
  ...rest
}) => (
  <FontAwesomeIcon
    icon={icon || type}
    className={className}
    {...rest}
  />
)

export { Icon as default }
