import Helmet from "react-helmet";
import React from 'react'

const helmet = ({
  title,
  description
}) => {

  return (
    <Helmet> 
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export { helmet as default }